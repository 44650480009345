import { captureException } from '@sentry/nextjs';
import { COOKIE_BROWSER_ID } from 'src/constants';
import { carmaCsrBizClient } from 'src/services/instance/carmaBizClient';
import { FilterListPayload, FiltersPayloadRow, SortValues } from 'src/types/CarFilters.types';
import {
  PRODUCT_CATALOGUE_LIMIT,
  ProductListDataPayload,
  ProductListDataRow,
  UnavailableProductListDataPayload,
} from 'src/types/CataloguePage.types';
import Cookies from 'universal-cookie';

/**
 * Fetch a collection of vehicles
 */
export const listVehicleData = (pageSize: number, page: number) => {
  return carmaCsrBizClient
    .get<ProductListDataPayload>(`vehicles`, {
      headers: {
        'Content-Type': 'application/plp+json',
      },
      searchParams: {
        pageSize,
        page,
      },
    })
    .json();
};

/**
 * Fetch all the vehicles
 */
export async function listAllVehicleData() {
  const listAll: ProductListDataRow[] = [];
  const pageSize = 50;
  let totalReturnedItems = 0;
  let currentPage = 0;

  // Build the list of all the slugs
  do {
    // await in a loop is required in this case
    // eslint-disable-next-line no-await-in-loop
    const result = await listVehicleData(pageSize, currentPage);

    listAll.push(...result.results);

    totalReturnedItems = result.results.length;
    currentPage += 1;
  } while (totalReturnedItems === pageSize);
  return listAll;
}

type fetchVehicleFiltersProps = {
  includeMakesWithNoResult?: boolean;
};

export const fetchVehicleFilters = async (props?: fetchVehicleFiltersProps) => {
  const searchParams = new URLSearchParams();
  if (props?.includeMakesWithNoResult !== undefined) {
    searchParams.append(`include_makes_with_no_results`, props.includeMakesWithNoResult.toString());
  }
  const url = `${process.env.REACT_APP_BIZ_API_URL}/vehicle-filters?${searchParams}`;
  const result = await fetch(url, {
    headers: { 'Content-Type': 'application/json' },
    next: {
      revalidate: 300,
      tags: ['plp', `plp-filters`],
    },
  });

  if (!result.ok) {
    throw new Error(`Failed to fetch data ${url}`);
  }

  const parsedResult = await result.json();

  return parsedResult as FilterListPayload;
};

export const fetchVehicles = async (
  filters: FiltersPayloadRow[] = [],
  page: number = 0,
  pageSize: number = PRODUCT_CATALOGUE_LIMIT,
  sort: SortValues = SortValues.Recommended,
): Promise<ProductListDataPayload> => {
  const url = `${process.env.REACT_APP_BIZ_API_URL}/vehicles`;
  const result = await fetch(url, {
    headers: { 'Content-Type': 'application/plp+json' },
    method: 'POST',
    body: JSON.stringify({
      filters,
      page,
      pageSize,
      sort,
    }),
    next: {
      revalidate: 300,
      tags: ['plp', 'plp-results'],
    },
  });

  if (!result.ok) {
    if (result.status !== 404) {
      captureException(`Failed to fetch data ${url}\n${JSON.stringify(result)}`);
    }
    return {
      total: 0,
      results: [],
    };
  }

  const parsedResult = await result.json();

  return parsedResult as ProductListDataPayload;
};

export const fetchWatchlistVehiclesBySku = async (skus: string[], page: number = 0, pageSize: number = 36) => {
  const url = `${process.env.REACT_APP_BIZ_API_URL}/vehicles/watchlist`;
  const result = await fetch(url, {
    headers: { 'Content-Type': 'application/watchlist+json' },
    method: 'POST',
    body: JSON.stringify({ skus, page, page_size: pageSize, sort: 'preserve' }),
    next: {
      revalidate: 300,
      tags: ['plp', 'plp-results'],
    },
  });

  if (!result.ok) {
    console.log(await result.text());
    throw new Error(`Failed to fetch data ${url}`);
  }

  const parsedResult = await result.json();

  return parsedResult as ProductListDataPayload;
};

export const fetchVehiclesBySku = async (skus: string[]) => {
  const url = `${process.env.REACT_APP_BIZ_API_URL}/vehicles`;
  const result = await fetch(url, {
    headers: { 'Content-Type': 'application/plp+json' },
    method: 'POST',
    body: JSON.stringify({ skus, sort: 'preserve' }),
    next: {
      revalidate: 300,
      tags: ['plp', 'plp-results'],
    },
  });

  if (!result.ok) {
    throw new Error(`Failed to fetch data ${url}`);
  }

  const parsedResult = await result.json();

  return parsedResult as ProductListDataPayload;
};

export const fetchUnavailableVehicles = async () => {
  const url = `${process.env.REACT_APP_BIZ_API_URL}/vehicles-unavailable`;
  const result = await fetch(url, {
    headers: { 'Content-Type': 'application/plp+json' },
    method: 'GET',
    next: {
      revalidate: 60,
      tags: ['plp', 'plp-results', 'plp-results-unavailable'],
    },
  });

  if (!result.ok) {
    throw new Error(`Failed to fetch data ${url}`);
  }

  const parsedResult = await result.json();

  return parsedResult as UnavailableProductListDataPayload;
};

export const fetchSimilarVehicles = async (sku: string, limit: number) => {
  const url = `${process.env.REACT_APP_BIZ_API_URL}/vehicles/predict/similar/${sku}?limit=${limit}`;
  const result = await fetch(url, {
    headers: { 'Content-Type': 'application/plp+json' },
    method: 'GET',
    next: {
      revalidate: 3600,
      tags: ['plp', 'plp-results', 'plp-results-similar'],
    },
  });

  if (!result.ok) {
    throw new Error(`Failed to fetch data ${url}`);
  }

  const parsedResult = await result.json();

  return parsedResult as ProductListDataPayload;
};

export const fetchRecommendedVehicles = async () => {
  const cookies = new Cookies();
  const browser_id = cookies.get(COOKIE_BROWSER_ID) ?? 'not_set';
  const url = `${process.env.REACT_APP_BIZ_API_URL}/vehicles/predict/recommended?browser_id=${browser_id}`;
  const result = await fetch(url, {
    headers: { 'Content-Type': 'application/json' },
    method: 'GET',
    next: {
      revalidate: 60,
      tags: ['plp', 'plp-results', 'plp-results-recommended', `plp-results-recommended-${browser_id}`],
    },
  });

  if (!result.ok) {
    throw new Error(`Failed to fetch data ${url}`);
  }

  const parsedResult = await result.json();

  return parsedResult as ProductListDataPayload;
};
