'use client';

import dynamic from 'next/dynamic';
import { FC, useState } from 'react';

import { Button } from 'src/general/components/Button/Button';

import styles from './OptInSection.module.scss';

const CarEnquiryFormModal = dynamic(
  () => import('src/pdp/components/CarEnquiryForm/CarEnquiryFormModal').then((mod) => mod.CarEnquiryFormModal),
  { ssr: false },
);

type Props = {
  buttonLabel: string;
};

export const OptInForm: FC<Props> = ({ buttonLabel }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <footer className={styles.footer}>
        <Button onClick={onOpen}>{buttonLabel}</Button>
      </footer>

      <CarEnquiryFormModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
