import { ComponentProps, useEffect } from 'react';
import { CarouselSection } from 'src/general/components/Carousel/CarouselSection';
import { IntersectionObserverHandler } from 'src/general/components/IntersectionObserverHandler/IntersectionObserverHandler';
import { pushToDataLayerFeatureEngagement } from 'src/utils/pushToDataLayerFeatureEngagement';
import styles from './RecentlyViewedCarousel.module.scss';

type Props = {
  tiles: ComponentProps<typeof CarouselSection>['tiles'];
};

export const RecentlyViewedCarouselSection = ({ tiles }: Props) => {
  useEffect(() => {
    pushToDataLayerFeatureEngagement({
      event: 'feature_engagement',
      payload: {
        type: 'load',
        feature: 'recentlyViewed',
        component: 'carousel',
      },
    });
  }, []);
  return (
    <IntersectionObserverHandler
      margins="-50%"
      onIntersecting={() => {
        pushToDataLayerFeatureEngagement({
          event: 'feature_engagement',
          payload: {
            type: 'view',
            feature: 'recentlyViewed',
            component: 'carousel',
          },
        });
      }}
    >
      <CarouselSection tiles={tiles} className={styles.recentlyViewedCarousel} />
    </IntersectionObserverHandler>
  );
};
