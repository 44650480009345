import { clsx } from 'clsx';
import { FC, useEffect } from 'react';
import { IntersectionObserverHandler } from 'src/general/components/IntersectionObserverHandler/IntersectionObserverHandler';
import { RecentSearch } from 'src/hygiene/components/flexible-content/RecentSearch/RecentSearch';
import { RecentFilterSearch } from 'src/hygiene/components/flexible-content/RecentSearch/RecentSearch.type';
import { pushToDataLayerFeatureEngagement } from 'src/utils/pushToDataLayerFeatureEngagement';
import styles from './RecentSearches.module.scss';

type RecentSearchesProps = {
  recentSearches: RecentFilterSearch[];
  className?: string;
};

export const RecentSearches: FC<RecentSearchesProps> = ({ recentSearches, className }) => {
  useEffect(() => {
    pushToDataLayerFeatureEngagement({
      event: 'feature_engagement',
      payload: {
        type: 'load',
        feature: 'recentSearch',
        component: 'list',
      },
    });
  }, []);

  const onClickSearch = (index: number) => {
    pushToDataLayerFeatureEngagement({
      event: 'feature_engagement_click',
      payload: {
        type: 'click',
        feature: 'recentSearch',
        component: 'list',
        index,
      },
    });
  };
  return (
    <IntersectionObserverHandler
      threshold={0.5}
      margins="0px"
      onIntersecting={() => {
        pushToDataLayerFeatureEngagement({
          event: 'feature_engagement',
          payload: {
            type: 'view',
            feature: 'recentSearch',
            component: 'list',
          },
        });
      }}
    >
      <section className={clsx(styles.root, className)}>
        <h2 className={clsx(styles.carouselHeader, 'c-fw-bold', 'c-fs-h4')}>Recent searches</h2>
        <div className={styles.searchesContainer}>
          {recentSearches.map((search, index) => (
            <RecentSearch key={search.id} className={styles.search} {...search} onClick={() => onClickSearch(index)} />
          ))}
        </div>
      </section>
    </IntersectionObserverHandler>
  );
};
