import { pushToDataLayer } from './pushToDataLayer';

interface TrackingFeatureEventInfo {
  event: 'feature_engagement' | 'feature_engagement_click';
  payload: {
    type: 'load' | 'view' | 'click';
    component?: string;
    feature?: string;
    sku?: string;
    index?: number;
  };
}

export const pushToDataLayerFeatureEngagement = ({
  event,
  payload: { type, component, feature, sku, index },
}: TrackingFeatureEventInfo) => {
  pushToDataLayer({
    event: event,
    type: type,
    feature,
    component: component,
    ...(sku && { sku }),
    ...(index !== undefined && { index }),
  });
};
