'use client';

import { FC } from 'react';

import { ProductCard } from 'src/plp/components/ProductCard/ProductCard';
import { BaseCarData } from 'src/types/Car.types';
import { pushToDataLayerProductCard } from 'src/utils/pushToDataLayerProductCard';

type Props = {
  carData: BaseCarData;
  weeklyRepayment: number;
  defaultRate: number;
  index: number;
  carouselName?: string;
  carouselValue?: string;
};

export const VehicleCarouselTile: FC<Props> = ({
  carData,
  weeklyRepayment,
  defaultRate,
  index,
  carouselName,
  carouselValue,
}) => (
  <ProductCard
    carData={carData}
    weeklyRepayment={weeklyRepayment}
    defaultRate={defaultRate}
    variant="default"
    onCardClick={
      carouselName && carouselValue
        ? () => {
            pushToDataLayerProductCard(carData, index, {
              event: 'carousel_tile_click',
              name: carouselName,
              value: carouselValue,
            });
          }
        : undefined
    }
  />
);
